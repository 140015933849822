<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <loading-panel v-if="loading"></loading-panel>
        <div
          v-bind:style="{ visibility: loading ? 'hidden' : 'visible' }"
          class="page-wrapper-table-body-inner"
        >
          <el-table
            class="align-items-center table-flush clickable"
            header-row-class-name="thead-light"
            :data="customers"
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
            @sort-change="sortChange"
            @row-click="selectRow"
          >
            <el-table-column min-width="90" :label="$t('COMMON.IMAGE')">
              <template v-slot="{ row }">
                <div class="customer-image">
                  <div class="customer-image-wrapper">
                    <img
                      v-if="row"
                      src="../../../../../../public/img/user-image.png"
                      class="avatar rounded-circle"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('CUSTOMERS.LAST_NAME')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.firstname ?? "" }} {{ row.lastname ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('CUSTOMERS.EMAIL')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.email ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('CUSTOMERS.PHONE')"
              prop="name"
              sortable="custom"
              min-width="250"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.phone ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('CUSTOMERS.NATIONALITY')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.nationality ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('CUSTOMERS.REGION')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.region ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('CUSTOMERS.ZIP_CODE')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.zipcode ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_VIEW_CUSTOMERS)"
                    >
                      <a
                        type="text"
                        @click="viewCustomer(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.VIEW_DETAILS") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_EDIT_CUSTOMERS)"
                    >
                      <a
                        type="text"
                        @click="editCustomer(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_DELETE_CUSTOMERS)"
                    >
                      <a
                        type="text"
                        @click="deleteCustomer(row.id)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="customers.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>

    <div
      v-if="(customers.length === 0) & !loading && isEmptyFilters()"
      class="no-data"
    >
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("CUSTOMERS.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddCustomer"
          v-if="$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{
            $t("CUSTOMERS.ADD_CUSTOMER")
          }}</span>
        </base-button>
      </div>
    </div>

    <div
      v-if="customers.length === 0 && !loading && !isEmptyFilters()"
      class="no-data"
    >
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("COMMON.NO_SEARCH_RESULT") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import LoadingPanel from "@/components/LoadingPanel.vue";

export default {
  name: "customer-list-table",

  components: {
    LoadingPanel,
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      customers: [],
      loading: false,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    }
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    editCustomer(customer) {
      this.$emit("onEditCustomer", customer);
    },

    async getList()
    {
      try
      {
        this.loading = true ;

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? {search: this.query} : {})
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage
          }
        } ;

        await this.$store.dispatch("customers/list", params) ;
        this.customers = this.$store.getters["customers/list"] ;
        this.total = this.$store.getters["customers/total"] ;

      }
      catch (e)
      {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      finally {
        this.loading = false ;
      }
    },

    async deleteCustomer(id) {
      const confirmation = await swal.fire({
        text: this.$t("CUSTOMERS.DELETE_THIS_CUSTOMER"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          /* await this.$store.dispatch("customer/destroy", id);
          await this.getList(); */
          swal.fire({
            text: this.$t("SPOTS.DELETE_SPOT_SUCCESS"),
            type: "info",
            customClass: {
              popup: "delete-confirm",
            },
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: this.$t("COMMON.END"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewCustomer(customer) {
      this.$emit("onViewCustomer", customer);
    },

    onAddCustomer() {
      this.$emit("onAddCustomer");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    selectRow(spot, ...args) {
      const target = args[1]?.target;

      if (target && target.classList.contains('fa-ellipsis-v')) {
        return false;
      }

      this.$emit("onViewCustomer", spot);
    },

    isEmptyFilters() {
      return !this.query;
    },
  },
};
</script>
