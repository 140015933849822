<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>{{ $t("CUSTOMERS.CUSTOMERS_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.CUSTOMER_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("CUSTOMERS.CUSTOMERS_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openCustomerCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("CUSTOMERS.ADD_CUSTOMER")
            }}</span>
          </base-button>
        </div>
      </div>
      <customer-list-table
        @onViewCustomer="openCustomerViewModal"
        @onEditCustomer="openCustomerEditModal"
        @onDeletCustomert="deleteCustomer"
        @onAddCustomer="openCustomerCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewCustomerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewCustomerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CUSTOMER_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeCustomerModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>{{ $t("CUSTOMERS.CUSTOMER_DETAILS") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-customer-component
              v-if="openCustomer"
              :customerId="openCustomer.id"
              @onEditCustomer="openCustomerEditModal"
              @onDeleteCustomer="deleteCustomer"
              @onReopen="reopenCustomer"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditCustomerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditCustomerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CUSTOMER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeCustomerModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("CUSTOMER.EDIT_CUSTOMER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-customer-component
              v-if="openCustomer"
              :customerId="openCustomer.id"
              @onViewCustomer="openCustomerViewModal"
              @onCloseCustomerModal="closeCustomerModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddCustomerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddCustomerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CUSTOMER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeCustomerModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("CUSTOMERS.ADD_CUSTOMER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-customer-component
              @onViewCustomer="openCustomerViewModal"
              @onCloseCustomerModal="closeCustomerModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import CustomerListTable from "./partials/CustomerListTable.vue";
import EditCustomerComponent from "./components/EditCustomerComponent.vue";
import AddCustomerComponent from "./components/AddCustomerComponent.vue";
import ViewCustomerComponent from "./components/ViewCustomerComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    CustomerListTable,
    EditCustomerComponent,
    AddCustomerComponent,
    ViewCustomerComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const customerId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewCustomerModalOpened = false;
    let isEditCustomerModalOpened = false;
    let isAddCustomerModalOpened = false;
    let openCustomer = null;
    if (customerId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewCustomerModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditCustomerModalOpened = true;
      }
      openCustomer = { id: customerId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddCustomerModalOpened = true;
    }
    return {
      isViewCustomerModalOpened: isViewCustomerModalOpened,
      isEditCustomerModalOpened: isEditCustomerModalOpened,
      isAddCustomerModalOpened: isAddCustomerModalOpened,
      openCustomer: openCustomer,
      renderKey: 1,
    };
  },

  mounted() {},

  methods: {
    openCustomerCreateModal() {
      this.closeCustomerModal();
      this.isAddCustomerModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Customers",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openCustomerViewModal(customer, reRender = false) {
      this.closeCustomerModal();
      this.openCustomer = customer;
      this.isViewCustomerModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Customers",
          query: { id: this.openCustomer.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openCustomerEditModal(customer) {
      this.closeCustomerModal();
      this.openCustomer = customer;
      this.isEditCustomerModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Customers",
          query: { id: this.openCustomer.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeCustomerModal() {
      this.isAddCustomerModalOpened = false;
      this.isViewCustomerModalOpened = false;
      this.isEditCustomerModalOpened = false;
      this.openCustomer = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Customers",
          query: {},
        }).href
      );
    },

    reopenCustomer(customer) {
      this.closeCustomerModal();
      this.openCustomerViewModal(customer);
    },

    async deleteCustomer(customer) {
      const confirmation = await swal.fire({
        text: this.$t("CUSTOMERS.DELETE_THIS_CUSTOMER"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      const deleteConform = await swal.fire({
        text: this.$t("CUSTOMERS.DELETE_CUSTOMER_SUCCESS"),
        type: "info",
        customClass: {
          popup: "delete-confirm",
        },
        buttonsStyling: false,
        showCancelButton: false,
        confirmButtonText: this.$t("COMMON.END"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          //await this.$store.dispatch("spots/destroy", customer.id);
          this.renderKey++;
          this.closeCustomerModal();
          deleteConform;
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
