<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img
          src="../../../../../../public/img/user-image.png"
          class="argon-image mb-2"
        />
        <!-- <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        /> -->
      </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ customer.lastName }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ customer.firstName }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ customer.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ customer.phone }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("CUSTOMERS.BIRTHDAY") }}</dt>
          <dd>
            {{ customer.birthday }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("CUSTOMERS.SEX") }}</dt>
          <dd>
            {{ customer.sex }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ customer.country }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("CUSTOMERS.NATIONALITY") }}</dt>
          <dd>
            {{ customer.nationality }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ customer.region }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ customer.city }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ customer.address }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ZIP_CODE") }}</dt>
          <dd>
            {{ customer.zipCode }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.LOCATION") }}</dt>
          <dd>
            {{ customer.location }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>{{ customer.created }}</dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ customer.updated }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "customer-view-global",

  props: [],

  components: {},

  data() {
    return {
      customer: {
        id: 1,
        firstName: "Alexis",
        lastName: "Duavnt",
        email: "alexisduvant@domaine.ca",
        phone: "+1418 233490865",
        birthday: "19/12/1996",
        sex: "Masculin",
        country: "Canada",
        nationalty: "Canadien",
        region: "Bas-St-Laurent",
        city: "Québèc",
        address: "QC, Sainte-Rose-du-Nord G0V 1T0",
        zipCode: "K1A 0T6",
        location: "Roulotte Confort",
        created: "Vendredi 05 Juilllet 2024 12:30",
        updated: "Lundi 08 Août 2024 18:00",
      },
    };
  },

  computed: {},

  created() {},

  methods: {
    customerUpdated() {
      this.$emit("customerUpdated", true);
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
