<template>
  <form
    class="add-form"
    ref="customer_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="add-form-inner">
      <div class="form-wrapper full">
        <image-selector
          :defaultImage="customer.avatar"
          ressource_name="customers"
          :ressource_id="customer.id ? customer.id : 0"
          field="avatar"
          @imageChanged="
            (file_url) => {
              customer.avatar = file_url;
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.attachment" />
      </div>

      <div class="form-wrapper title">
        <h2>{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</h2>
      </div>
      <div class="form-wrapper">
        <base-input
            :label="`${$t('COMMON.ORGANIZATION')} (*)`"
            :placeholder="$t('COMMON.ORGANIZATION')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !hideOrganization">
            <organization-selector
              :organization="null"
              :filterable="true"
              :showAll="false"
              :disabled="false"
              @organizationChanged="this.handleOrganizationChanged"/>
          </base-input>
          <validation-error :errors="apiValidationErrors.organization"/>
      </div>
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.LASTNAME')} (*)`"
          :placeholder="$t('COMMON.LASTNAME')"
          v-model="customer.lastname"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.lastname" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.FIRSTNAME')} (*)`"
          :placeholder="$t('COMMON.FIRSTNAME')"
          v-model="customer.firstname"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.firstname" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.EMAIL')} (*)`"
          :placeholder="$t('COMMON.EMAIL')"
          v-model="customer.email"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.email" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('CUSTOMERS.NATIONALITY')} (*)`"
          :placeholder="$t('CUSTOMERS.NATIONALITY')"
        >
          <country-selector
            :country="customer.nationality"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                customer.nationality = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.nationality" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('CUSTOMERS.BIRTHDAY')} (*)`"
          :placeholder="$t('CUSTOMERS.BIRTHDAY')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="customer.birthday"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.birthday" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('CUSTOMERS.SEX')} (*)`"
          :placeholder="$t('CUSTOMERS.SEX')"
        >
          <div class="radio-group-wrapper">
            <el-radio-group v-model="customer.sex">
              <el-radio :label="1">{{ $t("COMMON.MALE") }}</el-radio>
              <el-radio :label="2">{{ $t("COMMON.FEMALE") }}</el-radio>
              <el-radio :label="3">{{ $t("COMMON.OTHERS") }}</el-radio>
            </el-radio-group>
          </div>
        </base-input>
      </div>

      <!-- Phone number -->
      <div class="form-wrapper full">
        <div class="row equal">
          <div class="equal-item type">
            <base-input :label="`${$t('COMMON.TYPE')}`">
              <el-select
                :label="$t('COMMON.TYPE')"
                :placeholder="$t('COMMON.TYPE')"
                v-model="customer.phone_type"
                @change="
                  (type) => {
                    customer.phone_type = type;
                    onFormChanged();
                  }
                "
              >
                <el-option
                  v-for="(value, key) in phoneTypesOptions"
                  :key="key"
                  :value="value"
                  :label="$t(`COMMON.PHONE_TYPE_${value}`)"
                />
              </el-select>
            </base-input>
          </div>
          <div class="equal-item phone">
            <base-input :label="`${$t('COMMON.PHONE')}`">
              <phone-number-input
                :phoneNumber="customer.phone"
                @phoneNumberChanged="
                  (phone) => {
                    customer.phone = phone;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.phone" />
          </div>
          <div class="equal-item extension">
            <base-input
              :label="`${$t('COMMON.EXTENSION')}`"
              v-model="customer.phone_extension"
              @change="
                () => {
                  onFormChanged();
                }
              "
              :inputClasses="'extension-input'"
            />
            <validation-error :errors="apiValidationErrors.phone_extension" />
          </div>
        </div>
      </div>

      <!-- Other phone numbers -->
      <div class="form-wrapper full">
        <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
          <phone-numbers-selector
            :phoneNumbers="customer.other_phones"
            @phoneNumbersChanged="
              (phoneNumbers) => {
                customer.other_phones = phoneNumbers;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.other_phones" />
      </div>

      <!-- region adresse -->
      <div class="form-wrapper">
        <base-input
          :label="$t('CUSTOMERS.COUNTRY')"
          :placeholder="$t('CUSTOMERS.COUNTRY')"
        >
          <country-selector
            :country="customer.country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                customer.country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('CUSTOMERS.REGION')"
          :placeholder="$t('CUSTOMERS.REGION')"
        >
          <el-select v-model="customer.region">
            <el-option
              v-for="region in regions"
              :key="region.id"
              :label="region.name"
              :value="region.name"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.CITY')"
          :placeholder="$t('COMMON.CITY')"
        >
          <state-selector
            :country="customer.country"
            :state="customer.state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                customer.state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.state" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('CUSTOMERS.ZIP_CODE')"
          :placeholder="$t('CUSTOMERS.ZIP_CODE')"
          v-model="customer.zipcode"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>
      <!-- endregion -->

      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.PAYMENT") }}</h2>
      </div>

      <div class="form-wrapper width_1-4">
        <base-input :label="`${$t('CUSTOMERS.PAYMENT_METHOD')} (*)`">
          <el-select v-model="customer.paymentMode">
            <el-option
              v-for="payment in payments"
              :key="payment.id"
              :label="payment.name"
              :value="payment.name"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="form-wrapper width_2-3">
        <base-input
          :label="`${$t('COMMON.BILLING_ADDRESS')} (*)`"
          :placeholder="$t('COMMON.BILLING_ADDRESS')"
          v-model="customer.billing_address"
          @change="onFormChanged()"
        />
        <validation-error :errors="apiValidationErrors.billing_address" />
      </div>

      <div class="form-wrapper line-group">
        <p>
          {{
            $t("CUSTOMERS.ADD_PAYMENT_INFORMATION") +
            " ( " +
            $t("CUSTOMERS.OPTIONAL") +
            " )"
          }}
        </p>
        <base-input>
          <el-radio-group v-model="addPayment">
            <el-radio :label="1">{{ $t("COMMON.NO") }}</el-radio>
            <el-radio :label="2">{{ $t("COMMON.YES") }}</el-radio>
          </el-radio-group>
        </base-input>
      </div>

      <div v-if="addPayment === 2" class="form-wrapper full">
        <credit-card-selector
          :customer-id="customer.creditcard"
          v-model="selectedCreditCard"
          @creditCardChanged="(creditCard) => (selectedCreditCard = creditCard)"
        />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        class="btn cancel"
        type="button"
        @click="onCloseCustomerModal"
      >
        {{ $t("COMMON.CANCEL") }}
      </base-button>
      <base-button
        type="button"
        class="btn btn-sm kw-submit"
        @click.prevent="handleSubmit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ customer.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option, Radio, RadioGroup } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { phoneTypesOptions } from "@/constants/common";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import CreditCardSelector from "@/components/CreditCardSelector";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import {CUSTOMER_TYPE_INDIVIDUAL, CUSTOMER_TYPE_COMPANY} from "@/constants/customers";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ImageSelector,
    CreditCardSelector,
    [Select.name]: Select,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Option.name]: Option,
    PhoneNumberInput,
    PhoneNumbersSelector,
    CountrySelector,
    StateSelector,
    flatPicker,
    OrganizationSelector
  },

  mixins: [formMixin],

  props: ["customerData", "formErrors", "loading", "hideOrganization"],

  data() {
    return {
      customer: { ...this.customerData },
      permissions: [],
      models: models,
      phoneTypesOptions,
      addPayment: 1,
      selectedCreditCard: null,
      regions: [
        {
          id: 1,
          name: "Région de l’Atlantique",
        },
        {
          id: 2,
          name: "Centre du Canada",
        },
        {
          id: 3,
          name: "Provinces des Prairies",
        },
        {
          id: 4,
          name: "Côte Ouest",
        },
        {
          id: 5,
          name: "Nord",
        },
      ],
      payments: [
        {
          id: 1,
          name: "Carte bancaire",
        },
        {
          id: 2,
          name: "Paypal",
        },
      ],
    };
  },

  created() {},

  methods: {
    async handleSubmit()
    {
      let customerData = cloneDeep(this.customer);
      if (customerData.billing_entity_type === CUSTOMER_TYPE_INDIVIDUAL)
      {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }
      else if (customerData.billing_entity_type === CUSTOMER_TYPE_COMPANY)
      {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }
      
      customerData.billing_country = (customerData.country && customerData.country.length > 0) ? customerData.country : "N/A";
      customerData.billing_state = (customerData.state && customerData.state.length > 0) ? customerData.state : "N/A";
      customerData.billing_city = (customerData.city && customerData.city.length > 0) ? customerData.city : "N/A";
      customerData.billing_zipcode = (customerData.zipcode && customerData.zipcode.length > 0) ? customerData.zipcode : "N/A";
      customerData.billing_email = customerData.email;
      
      console.log(customerData) ;

      customerData = this.$fillUserResellerData(customerData);
      
      this.$emit("customerSubmitted", customerData);
    },

    handleOrganizationChanged(organizationId)
    {
      this.customer.organization.id = organizationId;
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onCloseCustomerModal() {
      this.$emit("onCloseCustomerModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
