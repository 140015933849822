<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!customer">
      <span class="loader"></span>
    </span>
    <spot-form
      v-if="customer"
      :loading="loading"
      :customerData="customer"
      :formErrors="formErrors"
      @spotSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseCustomerModal="onCloseCustomerModal"
    />
  </div>
</template>

<script>
import CustomerForm from "../partials/CustomerForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { CustomerForm },

  mixins: [alertLeave],

  props: {
    customerId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      spot: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        /* await this.$store.dispatch("spots/get", this.spotId);
        this.spot = this.$store.getters["spots/spot"]; */
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(customer) {
      this.loading = true;
      const customerData = cloneDeep(customer);

      try {
        /* await this.$store.dispatch("customers/update", customerData);
        this.$notify({
          type: "success",
          message: this.$t("CUSTOMERS.CUSTOMER_UPDATED"),
        });
        const spot = await this.$store.getters["customers/customer"]; */
        this.$emit("onViewCustomer", spot, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onCloseCustomerModal() {
      this.$emit("onCloseCustomerModal");
    },
  },
};
</script>
